import React, { useEffect } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import CQLogo from '../components/cqlogo';
import FooterPolicies from '../components/footer-policies';

interface Props {
    location: any
    pageContext: Context
}
interface Context {
    title: string
    lang: string
    langCode: string
    route: string
    translation: any
}
const NotFoundPage = ({location, pageContext }:Props) => {
  const { lang, langCode, translation, route } = pageContext;
  const FooterContent = () => (<>
    <Link key="link-1" className="link footer__link" to={`${route}note-from-our-authors`}><span>{ translation.general.authors_note }</span></Link>
    <FooterPolicies translation={translation}/>
    <Link key="logo" className="cq-logo" to={route}><CQLogo lang={ lang }/></Link>
  </>);
  useEffect(() => {
    document.documentElement.classList.add('in');
  }, []);
  return (
    <Layout pathname={ location.pathname} translation={ translation } lang={lang} route={route} about={true} footerContent={FooterContent}>
      <SEO title={ `404 ${  translation.general.not_found }` } lang={langCode}/>
      <div className="page-404">
        <div className="container">
          <div className="page-404__content">
            <h1 className="page-404__title">{  translation.general.not_found }</h1>
            <div className="page-404__inner">
              <div className="page-404__description">{  translation.general.not_found_description }</div>
              <br /><br />
              <div className="page-404__buttons">
                <Link className="button" to="/cn"><span>{  translation.general.not_found_button_text }</span></Link>
              </div>
              </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
